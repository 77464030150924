//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'

export default {
  auth: false,
  components: {
    Slick: () => import('vue-slick')
  },
  layout: 'front-page',
  data: () => ({
    showCarouselEvent: false,
    slickComp: '',
    slickOptions: {
      slidesToShow: 1,
      arrows: true,
      autoplay: true,
      dots: false,
      infinite: false
    },
    slickKlienOptions: {
      slidesToShow: 5,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      dots: false,
      infinite: false,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            arrows: false
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    },
    slickBlogOptions: {
      slidesToShow: 1,
      arrows: true,
      autoplay: true,
      dots: false,
      infinite: false
    },
    itemBlogs: [],
    itemEvents: {},
    itemPromos: {}
  }),
  computed: {},
  watch: {},
  mounted () {
    this.$nextTick(function () {
      this.slickComp = 'Slick'
    })
    this.loadBlog()
    this.loadEvent()
    this.loadPromo()
  },
  destroyed () {},
  methods: {
    async loadBlog () {
      const result = await this.$axios.get('/api/blog/list')
      this.itemBlogs = result.data.data
    },
    async loadEvent () {
      const params = { per_page: 2, search: this.search }

      await this.$axios
        .$get('/api/event/near', {
          params
        })
        .then((data) => {
          this.itemEvents = data.data
        })
        .catch(() => {})
        .finally(() => {})
    },
    async loadPromo () {
      const result = await this.$axios.get('/api/promo/list')
      this.itemPromos = result.data.data.slice(0, 4)
    },
    startDate (date) {
      moment.locale('id') // indonesia
      return moment(date).format('DD MMMM YYYY')
    }
  }
}
